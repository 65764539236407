import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./components/global";
import "./utils";
import "./libs";

// Midone Theme
import "./assets/sass/app.scss";

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

// 

import Toastify from "toastify-js";
Vue.prototype.$Toastify = Toastify

Vue.config.productionTip = false;

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
});
