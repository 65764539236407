import Vue from "vue";
import Vuex from "vuex";
import sideMenu from "./side-menu";
import userInfo from './userInfo'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    sideMenu: sideMenu,
    userInfo,
  }
});
