const state = () => {
  return {
    menu: [
      {
        icon: 'HomeIcon',
        title: '组件',
        subMenu: [
          {
            pageName: 'side-menu-file-manager',
            title: '文件管理'
          },
          {
            pageName: 'side-menu-icon',
            title: 'icon管理'
          },
          {
            pageName: 'side-menu-post',
            title: 'Add New Post'
          },
          {
            pageName: 'side-menu-button',
            title: '按钮'
          },
          {
            pageName: 'side-menu-modal',
            title: '对话模态框'
          },
          {
            pageName: 'side-menu-alert',
            title: 'Alert'
          },
          {
            pageName: 'side-menu-progress-bar',
            title: '进度条'
          },
          {
            pageName: 'side-menu-tooltip',
            title: '提示'
          },
          {
            pageName: 'side-menu-dropdown',
            title: '下拉列表'
          },
          {
            pageName: 'side-menu-toast',
            title: '吐司'
          },
          {
            pageName: 'side-menu-loading-icon',
            title: 'loading-icon'
          },
          {
            pageName: 'side-menu-regular-form',
            title: '表单'
          },
          {
            pageName: 'side-menu-datepicker',
            title: '日期选择器'
          }, {
            pageName: 'side-menu-file-upload',
            title: '上传图片'
          },
          {
            pageName: 'side-menu-wysiwyg-editor',
            title: '富文本编辑器'
          },
          {
            pageName: 'side-menu-chart',
            title: '图表'
          },
          {
            pageName: 'side-menu-validation',
            title: '校验'
          },
          {
            pageName: 'side-menu-image-zoom',
            title: '图片放大器'
          },
          {
            pageName: 'side-menu-regular-table',
            title: '表格'
          }
        ]
      },
      {
        icon: 'HomeIcon',
        pageName: 'lm-home',
        title: '首页'
      },
      {
        icon: 'BoxIcon',
        pageName: 'lm-commercial-ment',
        title: '商户管理'
      },
      {
        icon: 'ServerIcon',
        pageName: 'lm-service-provider-info',
        title: '服务商信息',
        hidden: true,
      },
      {
        icon: 'MonitorIcon',
        pageName: 'lm-pay-config',
        title: '支付配置',
        hidden: true,
      },
      {
        icon: 'PaperclipIcon',
        title: '活动申请',
        hidden: true,
        subMenu: [
          {
            icon: 'ZapIcon',
            pageName: 'lm-activity-apply-zfb',
            title: '支付宝活动'
          },
          {
            icon: 'ZapIcon',
            pageName: 'lm-activity-apply-wx',
            title: '微信活动'
          }
        ]
      },
      {
        icon: 'FileTextIcon',
        title: '订单管理',
        hidden: true,
        subMenu: [
          {
            icon: 'ZapIcon',
            pageName: 'lm-order-manage-pay',
            title: '支付订单'
          },
          {
            icon: 'ZapIcon',
            pageName: 'lm-order-manage-refund',
            title: '退款订单'
          },
          {
            icon: 'ZapIcon',
            pageName: 'lm-order-manage-transfer',
            title: '转账订单'
          },
          {
            icon: 'ZapIcon',
            pageName: 'lm-order-manage-sub-account',
            title: '分账流水'
          }
        ]

      },
      {
        icon: 'ToolIcon',
        pageName: 'lm-authority-setup',
        title: '系统设置',
        hidden: true,
        subMenu: [
          {
            icon: 'ZapIcon',
            pageName: 'lm-authority-setup',
            title: '权限管理'
          },
          {
            icon: 'ZapIcon',
            pageName: 'lm-Administrator-list',
            title: '管理员列表'
          },
        ]
      },
      {
        icon: 'ToolIcon',
        pageName: 'authorization',
        title: '授权状态',
        hidden : false,
      }
    ]
  };
}

// getters
const getters = {
  menu: state => state.menu
};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
