import Vue from 'vue';
import VueRouter from 'vue-router';
import Cookies from "js-cookie";
import Toastify from "toastify-js";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}


const routes = [
	{
		path: '/',
		component: () => import(/* webpackChunkName: 'main' */ '@/layouts/SideMenu'),
		children: [
			{
				path: 'side-menu-dashboard',
				name: 'side-menu-dashboard',
				meta: { title: '会员中心' },
				component: () => import('@/views/Dashboard')
			},
			{
				path: '/',
				name: 'lm-home',
				meta: { title: '首页' },
				component: () => import('@/pages/lm-home/index')
			},
			{
				path: 'lm-pay-config',
				name: 'lm-pay-config',
				meta: { title: '支付配置' },
				component: () => import('@/pages/lm-pay-config/index')
			},
			{
				path: 'lm-commercial-ment',
				name: 'lm-commercial-ment',
				meta: { title: '商户管理' },
				component: () => import('@/pages/lm-commercial-ment/index')
			},
			{
				path: 'lm-service-provider-info',
				name: 'lm-service-provider-info',
				meta: { title: '服务商信息' },
				component: () => import('@/pages/lm-service-provider-info/index')
			},
			{
				path: 'lm-authority-setup',
				name: 'lm-authority-setup',
				meta: { title: '系统设置' },
				component: () => import('@/pages/lm-authority-setup/index')
			},
			// {
			// 	path: 'lm-activity-apply',
			// 	name: 'lm-activity-apply',
			// 	meta: { title: '活动申请' },
			// 	component: () => import('@/pages/lm-activity-apply/index')
			// },
			{
				path: 'lm-activity-apply-zfb',
				name: 'lm-activity-apply-zfb',
				meta: { title: '支付宝活动' },
				component: () => import('@/pages/lm-activity-apply/zfb/index')
			},
			{
				path: 'lm-order-manage-pay',
				name: 'lm-order-manage-pay',
				meta: { title: '支付订单' },
				component: () => import('@/pages/lm-order-manage/pay-order/index')
			},
			{
				path: 'lm-Administrator-list',
				name: 'lm-Administrator-list',
				meta: { title: '管理员列表' },
				component: () => import('@/pages/lm-Administrator-list/index')
			},
			{
				path: 'lm-order-manage-refund',
				name: 'lm-order-manage-refund',
				meta: { title: '退款订单' },
				component: () => import('@/pages/lm-order-manage/refund-order/index')
			},
			{
				path: 'lm-order-manage-transfer',
				name: 'lm-order-manage-transfer',
				meta: { title: '转账订单' },
				component: () => import('@/pages/lm-order-manage/transfer-order/index')
			},
			{
				path: 'lm-order-manage-sub-account',
				name: 'lm-order-manage-sub-account',
				meta: { title: '分账流水' },
				component: () => import('@/pages/lm-order-manage/sub-account-order/index')
			},

			{
				path: 'lm-activity-apply-wx',
				name: 'lm-activity-apply-wx',
				meta: { title: '微信活动' },
				component: () => import('@/pages/lm-activity-apply/wx/index')
			},
			{
				path: 'file-manager',
				name: 'side-menu-file-manager',
				meta: { title: '文件管理' },
				component: () => import('@/views/FileManager')
			},
			{
				path: 'point-of-sale',
				name: 'side-menu-point-of-sale',
				meta: { title: '管理中心' },
				component: () => import('@/views/PointOfSale')
			},
			{
				path: 'chat',
				name: 'side-menu-chat',
				meta: { title: '管理中心' },
				component: () => import('@/views/Chat')
			},
			{
				path: 'post',
				name: 'side-menu-post',
				meta: { title: '管理中心' },
				component: () => import('@/views/Post')
			},
			{
				path: 'crud-data-list',
				name: 'side-menu-crud-data-list',
				meta: { title: '管理中心' },
				component: () => import('@/views/CrudDataList')
			},
			{
				path: 'crud-form',
				name: 'side-menu-crud-form',
				meta: { title: '管理中心' },
				component: () => import('@/views/CrudForm')
			},
			{
				path: 'users-layout-1',
				name: 'side-menu-users-layout-1',
				meta: { title: '管理中心' },
				component: () => import('@/views/UsersLayout1')
			},
			{
				path: 'users-layout-2',
				name: 'side-menu-users-layout-2',
				meta: { title: '管理中心' },
				component: () => import('@/views/UsersLayout2')
			},
			{
				path: 'users-layout-3',
				name: 'side-menu-users-layout-3',
				meta: { title: '管理中心' },
				component: () => import('@/views/UsersLayout3')
			},
			{
				path: 'profile-overview-1',
				name: 'side-menu-profile-overview-1',
				meta: { title: '管理中心' },
				component: () => import('@/views/ProfileOverview1')
			},
			{
				path: 'profile-overview-2',
				name: 'side-menu-profile-overview-2',
				meta: { title: '管理中心' },
				component: () => import('@/views/ProfileOverview2')
			},
			{
				path: 'profile-overview-3',
				name: 'side-menu-profile-overview-3',
				component: () => import('@/views/ProfileOverview3')
			},
			{
				path: 'wizard-layout-1',
				name: 'side-menu-wizard-layout-1',
				meta: { title: '管理中心' },
				component: () => import('@/views/WizardLayout1')
			},
			{
				path: 'wizard-layout-2',
				name: 'side-menu-wizard-layout-2',
				meta: { title: '管理中心' },
				component: () => import('@/views/WizardLayout2')
			},
			{
				path: 'wizard-layout-3',
				name: 'side-menu-wizard-layout-3',
				meta: { title: '管理中心' },
				component: () => import('@/views/WizardLayout3')
			},
			{
				path: 'blog-layout-1',
				name: 'side-menu-blog-layout-1',
				meta: { title: '管理中心' },
				component: () => import('@/views/BlogLayout1')
			},
			{
				path: 'blog-layout-2',
				name: 'side-menu-blog-layout-2',
				meta: { title: '管理中心' },
				component: () => import('@/views/BlogLayout2')
			},
			{
				path: 'blog-layout-3',
				name: 'side-menu-blog-layout-3',
				meta: { title: '管理中心' },
				component: () => import('@/views/BlogLayout3')
			},
			{
				path: 'pricing-layout-1',
				name: 'side-menu-pricing-layout-1',
				meta: { title: '管理中心' },
				component: () => import('@/views/PricingLayout1')
			},
			{
				path: 'pricing-layout-2',
				name: 'side-menu-pricing-layout-2',
				meta: { title: '管理中心' },
				component: () => import('@/views/PricingLayout2')
			},
			{
				path: 'invoice-layout-1',
				name: 'side-menu-invoice-layout-1',
				meta: { title: '管理中心' },
				component: () => import('@/views/InvoiceLayout1')
			},
			{
				path: 'invoice-layout-2',
				name: 'side-menu-invoice-layout-2',
				meta: { title: '管理中心' },
				component: () => import('@/views/InvoiceLayout2')
			},
			{
				path: 'faq-layout-1',
				name: 'side-menu-faq-layout-1',
				meta: { title: '管理中心' },
				component: () => import('@/views/FaqLayout1')
			},
			{
				path: 'faq-layout-2',
				name: 'side-menu-faq-layout-2',
				meta: { title: '管理中心' },
				component: () => import('@/views/FaqLayout2')
			},
			{
				path: 'faq-layout-3',
				name: 'side-menu-faq-layout-3',
				meta: { title: '管理中心' },
				component: () => import('@/views/FaqLayout3')
			},
			{
				path: 'update-profile',
				name: 'side-menu-update-profile',
				meta: { title: '管理中心' },
				component: () => import('@/views/UpdateProfile')
			},
			{
				path: 'change-password',
				name: 'side-menu-change-password',
				component: () => import('@/views/ChangePassword')
			},
			{
				path: 'regular-table',
				name: 'side-menu-regular-table',
				meta: { title: '管理中心' },
				component: () => import('@/views/RegularTable')
			},
			{
				path: 'tabulator',
				name: 'side-menu-tabulator',
				meta: { title: '管理中心' },
				component: () => import('@/views/Tabulator')
			},
			{
				path: 'accordion',
				name: 'side-menu-accordion',
				meta: { title: '管理中心' },
				component: () => import('@/views/Accordion')
			},
			{
				path: 'button',
				name: 'side-menu-button',
				meta: { title: '管理中心' },
				component: () => import('@/views/Button')
			},
			{
				path: 'modal',
				name: 'side-menu-modal',
				meta: { title: '管理中心' },
				component: () => import('@/views/Modal')
			},
			{
				path: 'alert',
				name: 'side-menu-alert',
				meta: { title: '管理中心' },
				component: () => import('@/views/Alert')
			},
			{
				path: 'progress-bar',
				name: 'side-menu-progress-bar',
				meta: { title: '管理中心' },
				component: () => import('@/views/ProgressBar')
			},
			{
				path: 'tooltip',
				name: 'side-menu-tooltip',
				meta: { title: '管理中心' },
				component: () => import('@/views/Tooltip')
			},
			{
				path: 'dropdown',
				name: 'side-menu-dropdown',
				meta: { title: '管理中心' },
				component: () => import('@/views/Dropdown')
			},
			{
				path: 'toast',
				name: 'side-menu-toast',
				meta: { title: '管理中心' },
				component: () => import('@/views/Toast')
			},
			{
				path: 'typography',
				name: 'side-menu-typography',
				meta: { title: '管理中心' },
				component: () => import('@/views/Typography')
			},
			{
				path: 'icon',
				name: 'side-menu-icon',
				meta: { title: '管理中心' },
				component: () => import('@/views/Icon')
			},
			{
				path: 'loading-icon',
				name: 'side-menu-loading-icon',
				meta: { title: '管理中心' },
				component: () => import('@/views/LoadingIcon')
			},
			{
				path: 'regular-form',
				name: 'side-menu-regular-form',
				meta: { title: '管理中心' },
				component: () => import('@/views/RegularForm')
			},
			{
				path: 'datepicker',
				name: 'side-menu-datepicker',
				meta: { title: '管理中心' },
				component: () => import('@/views/Datepicker')
			},
			{
				path: 'tail-select',
				name: 'side-menu-tail-select',
				meta: { title: '管理中心' },
				component: () => import('@/views/TailSelect')
			},
			{
				path: 'file-upload',
				name: 'side-menu-file-upload',
				meta: { title: '管理中心' },
				component: () => import('@/views/FileUpload')
			},
			{
				path: 'wysiwyg-editor',
				name: 'side-menu-wysiwyg-editor',
				meta: { title: '管理中心' },
				component: () => import('@/views/WysiwygEditor')
			},
			{
				path: 'validation',
				name: 'side-menu-validation',
				meta: { title: '管理中心' },
				component: () => import('@/views/Validation')
			},
			{
				path: 'chart',
				name: 'side-menu-chart',
				meta: { title: '管理中心' },
				component: () => import('@/views/Chart')
			},
			{
				path: 'slider',
				name: 'side-menu-slider',
				meta: { title: '管理中心' },
				component: () => import('@/views/Slider')
			},
			{
				path: 'image-zoom',
				name: 'side-menu-image-zoom',
				meta: { title: '管理中心' },
				component: () => import('@/views/ImageZoom')
			},
			{
				path: 'profile',
				name: 'profile',
				meta: { title: '个人资料' },
				component: () => import('@/views/Profile')
			},
			{
				path: 'invitation',
				name: 'invitation',
				meta: { title: '邀请赚钱' },
				component: () => import('@/views/Invitation')
			},
			{
				path: 'myApp',
				name: 'my-app',
				meta: { title: '我的应用' },
				component: () => import('@/views/MyApp')
			}
		]
	},
	{
		path: '/login',
		name: 'login',
		meta: { title: '登录' },
		component: () => import('@/views/Login'),
		beforeEnter: (to, from, next) => {
			if (Cookies.get("accessToken")) {
				next('/')
				Toastify({
					node: cash(`<span>已登陆。</span>`)[0],
					duration: 3000,
					newWindow: true,
					close: true,
					stopOnFocus: true,
				}).showToast();
			}
			next()
		}
	},
	{
		path: '/register',
		name: 'register',
		meta: { title: '注册' },
		component: () => import('@/views/Register')
	},
	{
		path: '/retrieve-password',
		name: 'retrieve-password',
		meta: { title: '找回密码' },
		component: () => import('@/views/retrieve-password')
	},
	{
		path: '/404',
		name: 'error-page',
		meta: { title: '404' },
		component: () => import('@/views/ErrorPage')
	},
	{
		path: "*",
		redirect: '/404',
		hidden: true,
	},
	{
		path: '/authorization',
		name: 'authorization',
		meta: { title: '授权状态' },
		hidden: true,
		component: () => import('@/pages/lm-authorization-page/index')
	},
	{
		path: '/pay-callback',
		name: 'pay-callback',
		meta: { title: '支付回调' },
		hidden: true,
		component: () => import('@/pages/lm-pay-callback/index')
	}
];


const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	}
});


router.beforeEach((to, from, next) => {
	document.title = to.meta.title ? to.meta.title + ' - 智莺支付联盟' : '智莺支付联盟'



	next()
})

export default router;
