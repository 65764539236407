const state = () => {}

// getters
const getters = {
  
};

// actions
const actions = {
  saveUserInfo({ commit },data){
    commit('USER_INFO',data)
  }
};

// mutations
const mutations = {
  USER_INFO( state ,info ){
    state = info
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
